<template>
    <Teleport to="body">
        <Transition name="modal-container">
            <dialog ref="modal" v-if="isOpen" role="dialog" class="fixed w-full h-full bg-black-true/50 top-0 left-0 p-2 flex justify-center z-[99]" :class="scrollable ? 'overflow-auto' : 'items-center'" @click.self="closeModal" :data-qa="qaName" @keyup.escape="closeModal">
                <Transition name="modal" @leave="isOpen = false" @enter="resize()">
                    <div class="w-full relative default" :class="{small, medium, large}" ref="contentWrapper" v-if="isOpenInner">
                        <button class="close" ref="closeBtn" @click="closeModal" v-if="!noClose">
                            <span></span>
                            <span></span>
                            <span class="sr-only">Close</span>
                        </button>
                        <div class="w-full mx-auto default bg-white rounded-large p-8 md:p-14 relative" :class="{small, medium, large}" ref="content">
                            <slot></slot>
                        </div>
                    </div>
                </Transition>
            </dialog>
        </Transition>
    </Teleport>
</template>

<script>
import * as Analytics from '@/Util/reporting.js';

export default {
    components: {
    },
    emits: [
        'closeModal',
    ],
    props: {
        qaName: String,
        small: {
            type: Boolean,
            default: false,
        },
        medium: {
            type: Boolean,
            default: false,
        },
        large: {
            type: Boolean,
            default: false,
        },
        noClose: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            isOpen: false,
            isOpenInner: false,
            scrollable: false,
            contentHeight: 0,
            prevFocus: {},
        }
    },
    beforeMount() {
        this.prevFocus = document.activeElement;
    },
    mounted() {
        window.addEventListener('resize', () => {
            if (!!this.$refs.contentWrapper) {
                this.resize();
            }
        });
    },
    beforeUnmount() {
        this.prevFocus.focus();
    },
    unmounted() {
        this.prevFocus = {};
    },
    methods: {
        openModal() {
            this.isOpen = true;

            Analytics.pushToDataLayer({
                event: 'click',
                attributes: {
                    section: 'Modals',
                    action: 'Modal opened',
                    event_label: this.qaName.replace('_modal', ''),
                },
            });

            this.$nextTick(() => {
                this.isOpenInner = true;
                this.$refs.modal.focus();
            });
        },
        closeModal() {
            if (!this.noClose) {
                Analytics.pushToDataLayer({
                    event: 'click',
                    attributes: {
                        section: 'Modals',
                        action: 'Modal closed',
                        event_label: this.qaName.replace('_modal', ''),
                    },
                });

                this.isOpenInner = false;
                this.$emit('closeModal');
            }
        },
        resize() {
            this.$refs.contentWrapper.style.height = this.$refs.content.offsetHeight + 'px';
            if (this.$refs.content.offsetHeight > window.innerHeight) {
                this.scrollable = true;
            } else {
                this.scrollable = false;
            }
        }
    },
    expose: [
        'resize',
        'closeModal',
        'openModal'
    ],
    computed: {
        getQaName() {
            return this.qaName;
        }
    }
};
</script>

<style lang="postcss" scoped>

.modal-enter-active,
.modal-leave-active {
    @apply transition-all duration-200 ease-in-out;
}

.modal-enter-from,
.modal-leave-to {
    @apply translate-y-full opacity-0;
}
.modal-container-enter-active,
.modal-container-leave-active {
    @apply transition-all duration-200 ease-in-out;
}

.modal-container-enter-from,
.modal-container-leave-to {
    @apply opacity-0;
}

.default {
    @apply max-w-2xl;
}
.small {
    @apply max-w-xl;
}
.medium {
    @apply max-w-2xl;
}
.large {
    @apply max-w-3xl;
}

.close {
    @apply z-50 w-[35px] h-[35px] absolute top-[10px] right-[10px] flex justify-center items-center;

    span {
        &:first-child {
            @apply block absolute h-[2px] bg-black-default w-[25px] rotate-45;
        }

        &:nth-child(2) {
            @apply block absolute h-[2px] bg-black-default w-[25px] -rotate-45;
        }
    }

}
</style>
